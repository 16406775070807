<template>
    <div id="wrapper" :class="deviceClass">
        <Header />
        <main id="main" :class="wrapperClass">
            <slot></slot>
        </main>
        <Footer />
    </div>
</template>

<script>
import Header from "@/esf_kerkrade_vitanatura/core/components/Header.vue";
import Footer from "@/esf_kerkrade_vitanatura/core/components/Footer.vue";

export default {
    name: "AppLayout",
    components: { Header, Footer },
    props: {
        wrapperClass: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            deviceClass: 'desktop'
        };
    },
    mounted() {
        this.resizeWindow();
        window.addEventListener("resize", this.resizeWindow);
    },
    methods: {
        resizeWindow() {
            let windowWidth = window.innerWidth;
            if (windowWidth > 992) {
                this.deviceClass = 'desktop';
            } else {
                this.deviceClass = 'mobile';
            }
        }
    }
}
</script>

<style>
main#main {
    @media screen and (max-width: 1024px) {
        margin-top: 0px;
    }

    @media screen and (max-width: 992px) {
        margin-top: 0px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 70px;
    }

    @media screen and (max-width: 425px) {
        margin-top: 51px;
    }
}
</style>